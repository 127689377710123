

















































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import { dispatchSignup } from "@/store/main/actions";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import _ from "lodash";

@Component({
  components: { VuetifyGoogleAutocomplete, Header, Footer },
})
export default class TestApply extends Vue {
  public valid = true;
  public email = "notification@horizonfiber.ph";
  public password = "test1234";
  public password2 = "test1234";
  public appName = appName;
  public unit = "";
  public address = "";
  public firstName = "Test";
  public lastName = "User";
  public phoneNumber = "123-456-7890";
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public addressData = "";
  public agree = true;
  public eligible = null;

  $refs!: Vue["$refs"] & {
    map: typeof VuetifyGoogleAutocomplete;
  };
  public get loginError() {
    return readLoginError(this.$store);
  }

  public validate() {
    return (
      !!this.email &&
      !!this.password &&
      !!this.password2 &&
      !!this.firstName &&
      !!this.lastName &&
      !!this.phoneNumber &&
      !!this.address &&
      this.password === this.password2 &&
      !!this.agree
    );
  }

  public markerMoved(marker) {
    console.log(marker);
    console.log(marker.latLng.lat());
    console.log(marker.latLng.lng());
    var lat = marker.latLng.lat();
    var lng = marker.latLng.lng();
    this.$refs.map.update(`${lat},${lng}`);
    // = marker.position
  }

  public getAddressData(addressData, placeResultData, id) {
    this.addressData = addressData;
    this.place = placeResultData;
    this.placeId = id;
    this.addressInfo = `Address Detail: ${JSON.stringify(
      addressData
    )} ${JSON.stringify(placeResultData)} ${id}`;
    console.log(addressData, placeResultData, id);
  }

  public updateAddress(newAddress) {
    this.address = newAddress;
    setTimeout(() => {
      this.checkAddress();
    }, 1000);
  }
  public async checkAddress() {
    let referral_cid = "";
    if (this.$cookies.isKey("referral_cid")) {
      referral_cid = this.$cookies.get("referral_cid");
    }
    const response = await dispatchSignup(this.$store, {
      checkAddress: true,
      firstName: this.firstName,
      lastName: this.lastName,
      unit: this.unit,
      address: this.address,
      addressData: `${JSON.stringify(this.addressData)}`,
      placeData: `${JSON.stringify(this.place)}`,
      placeId: `${JSON.stringify(this.placeId)}`,
      phone: this.phoneNumber,
      email: this.email,
      password: this.password,
      referral_cid: referral_cid,
      referral: "",
      referral_other: "",
    });
    console.log(response);
    this.eligible = _.get(response, "eligible", false);
  }
  public submit() {
    let referral_cid = "";
    if (this.$cookies.isKey("referral_cid")) {
      referral_cid = this.$cookies.get("referral_cid");
    }
    dispatchSignup(this.$store, {
      checkAddress: false,
      firstName: this.firstName,
      lastName: this.lastName,
      unit: this.unit,
      address: this.address,
      addressData: `${JSON.stringify(this.addressData)}`,
      placeData: `${JSON.stringify(this.place)}`,
      placeId: `${JSON.stringify(this.placeId)}`,
      phone: this.phoneNumber,
      email: this.email,
      password: this.password,
      referral_cid: referral_cid,
      referral: "",
      referral_other: "",
    });
  }
}
